const ComponentVideo = gql`
  fragment ComponentVideo on ComponentVideo {
    provider
    url
    ratio
    controls
    autoplay
    mute
    subtitles
    caption
    asset {
      url
    }
  }
`;

export default ComponentVideo;
