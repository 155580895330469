const CATEGORY_COLLECTION = gql`
  query CATEGORY_COLLECTION {
    categoryCollection {
      total
      items {
        name
        slug
        order
        isEligibleForPosts
        parent {
          name
          slug
          parent {
            name
            slug
          }
        }
        sys {
          id
        }
      }
    }
  }
`;

export default CATEGORY_COLLECTION;
