const ComponentCustomLink = gql`
  fragment ComponentCustomLink on ComponentCustomLink {
    icon
    color
    type
    size
    link {
      text
      url
      target
      ariaLabel
    }
  }
`;

export default ComponentCustomLink;
