const AUTHOR_BY_SLUG = gql`
  query AUTHOR_BY_SLUG($slug: String!) {
    authorCollection(limit: 1, where: { slug: $slug }) {
      items {
        name
        slug
        role
        image {
          title
          description
          url
        }
        sys {
          id
        }
      }
    }
  }
`;

export default AUTHOR_BY_SLUG;
