const LANDING_PAGE_QUERY = gql`
  query LANDING_PAGE_QUERY {
    landingPageCollection(limit: 1, where: { internalName: "home" }) {
      total
      items {
        mainHeaderImage {
          url
          description
        }
        seo {
          title
          description
          image {
            title
            description
            url
          }
          keywords
          noindex
          nofollow
        }
        mainHeaderImagesCollection {
          items {
            url
            description
          }
        }
      }
    }
  }
`;

export default LANDING_PAGE_QUERY;
