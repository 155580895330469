//* Image formats
enum ImageFormats {
  JPG = "jpg",
  PNG = "png",
  WEBP = "webp",
  GIF = "gif",
  AVIF = "avi"
}

//* Image focus areas while cropping
enum ImageFocusAreas { //* use query 'f='
  right = "right",
  center = "center",
  top = "top",
  bottom = "bottom",
  left = "left",
  topRight = "top_right",
  topLeft = "top_left",
  bottomRight = "top_left",
  bottomLeft = "bottom_left",
  mainFace = "face", //* Focus of largest face in the image
  allFaces = "faces" //* Detect all face and crop
}

//* Image scale or crop
enum ImageScaleRatios { //* use query 'fit='
  pad = "pad",
  fill = "fill",
  scale = "scale",
  crop = "crop",
  thumb = "thumb" // To create thumbnail of image
}

export { ImageFocusAreas, ImageFormats, ImageScaleRatios };
