import { ICategoriesResponse } from "~/types/contentful/responses";
import { ICategory } from "~/types/page-service";

const mapToCategory = (cms: ICategoriesResponse): ICategory => {
  return {
    name: cms.name,
    slug: cms.slug,
    order: cms.order,
    parent: cms.parent,
    isEligibleForPosts: cms.isEligibleForPosts
  };
};

export { mapToCategory };
