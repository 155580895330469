const ComponentCardRtDownload = gql`
  fragment ComponentCardRtDownload on ComponentCardRtDownload {
    title
    authors
    cardLinkUrl
    downloadLinkUrl
  }
`;

export default ComponentCardRtDownload;
