import gql from "graphql-tag";

const AUTHORS = gql`
  query AUTHORS {
    authorCollection {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

export default AUTHORS;
