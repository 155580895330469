import gql from "graphql-tag";

const API_POSTS = gql`
  query API_POSTS(
    $limit: Int
    $skip: Int
    $search: String
    $categories: [String]
  ) {
    pageBlogPostCollection(
      limit: $limit
      skip: $skip
      order: publishedOn_DESC
      where: {
        OR: [{ title_contains: $search }, { content_contains: $search }]
        categories: { slug_in: $categories }
      }
    ) {
      total
      items {
        title
        slug
        categoriesCollection(where: { slug_in: $categories }) {
          items {
            name
            sys {
              id
            }
          }
        }
        featuredMedia {
          description
          url
        }
        excerpt
        publishedOn
        sys {
          id
        }
      }
    }
  }
`;

export default API_POSTS;
