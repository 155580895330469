const GET_TAG_BY_SLUG = gql`
  query GET_TAG_BY_SLUG($slug: String) {
    tagCollection(where: { slug: $slug }, limit: 1) {
      items {
        name
        slug
      }
    }
  }
`;

export default GET_TAG_BY_SLUG;
