const BRIEF_POST_COLLECTION = gql`
  query BRIEF_POST_COLLECTION(
    $limit: Int
    $skip: Int
    $search: String
    $categories: [String]
    $author: String
    $tag: String
  ) {
    pageBlogPostCollection(
      limit: $limit
      skip: $skip
      order: publishedOn_DESC
      where: {
        OR: [{ title_contains: $search }, { content_contains: $search }]
        categories: { name_in: $categories }
        author: { name: $author }
        tags: { slug: $tag }
      }
    ) {
      total
      items {
        title
        slug
        categoriesCollection(where: { name_in: $categories }) {
          items {
            name
            sys {
              id
            }
          }
        }
        tagsCollection {
          items {
            name
            slug
          }
        }
        featuredMedia {
          description
          url
        }
        excerpt
        publishedOn
        sys {
          id
        }
      }
    }
  }
`;

export default BRIEF_POST_COLLECTION;
