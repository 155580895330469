import type { IFooter, IIbar } from "~/types/journal-pages-2022";
import { getFormerKey } from "./useCacheService";

export const useIbarFooterService = () => {
  const journalsPagesEndpoint = useRuntimeConfig().public.journalsPagesUrl;
  const getIbarComponent = async (): Promise<IIbar> => {
    const ibarEndpoint = `${journalsPagesEndpoint}/components/ibar`;

    const { data, error } = await useAsyncData("ibar", async () => {
      const key = getFormerKey("getIbarComponent", "ibar");
      const { value, addToCache } = await useDataCache<IIbar>(key);
      if (value) {
        return value;
      }

      const response = await $fetch<IIbar>(ibarEndpoint);
      addToCache(response, ["ibar"], 60 * 60);
      return response;
    });
    return new Promise<IIbar>((resolve, reject) => {
      if (data.value) resolve(data.value);
      if (error.value) reject(error.value);
    });
  };

  const getFooterComponent = async (): Promise<IFooter> => {
    const footerEndpoint = `${journalsPagesEndpoint}/components/footer`;

    const { data, error } = await useAsyncData("footer", async () => {
      const key = getFormerKey("getFooterComponent", "footer");
      const { value, addToCache } = await useDataCache<IFooter>(key);
      if (value) {
        return value;
      }

      const response = await $fetch<IFooter>(footerEndpoint);
      addToCache(response, ["footer"], 60 * 60);
      return response;
    });
    return new Promise<IFooter>((resolve, reject) => {
      if (data.value) resolve(data.value);
      if (error.value) reject(error.value);
    });
  };

  return {
    getIbarComponent,
    getFooterComponent
  };
};
