import {
  ComponentImageLink,
  ComponentVideo,
  ComponentSocialMedia,
  ComponentGrid,
  ComponentCustomLink
} from "../fragments";

const POST_BY_SLUG = gql`
  query POST_BY_SLUG($slug: String, $preview: Boolean) {
    pageBlogPostCollection(
      limit: 1
      where: { slug: $slug }
      preview: $preview
    ) {
      items {
        title
        slug
        publishedOn
        excerpt
        featuredMedia {
          url
        }
        sys {
          publishedAt
        }
        relatedContentCollection(limit: 3) {
          items {
            title
            slug
            publishedOn
            featuredMedia {
              url
              description
            }
          }
        }
        categoriesCollection {
          items {
            slug
            name
          }
        }
        tagsCollection {
          items {
            name
            slug
          }
        }
        author {
          name
          slug
          role
          image {
            title
            description
            url
          }
        }
        featuredMedia {
          description
          url
        }
        autoInjectFeaturedImage
        content {
          json
          links {
            assets {
              __typename
              block {
                sys {
                  id
                }
                url
                description
                contentType
              }
            }
            entries {
              inline {
                __typename
                sys {
                  id
                }
              }
              block {
                __typename
                sys {
                  id
                }
                ...ComponentCustomLink
                ...ComponentSocialMedia
                ...ComponentGrid
                ...ComponentImageLink
                ...ComponentVideo
              }
            }
          }
        }
      }
    }
  }
  ${ComponentCustomLink}
  ${ComponentGrid}
  ${ComponentImageLink}
  ${ComponentVideo}
  ${ComponentSocialMedia}
`;

export default POST_BY_SLUG;
