const ComponentImageLink = gql`
  fragment ComponentImageLink on ComponentImageLink {
    url
    target
    ariaLabel
    image {
      title
      description
      url
    }
  }
`;

export default ComponentImageLink;
