import gql from "graphql-tag";

const POSTSRSS = gql`
  query POSTSRSS($limit: Int) {
    pageBlogPostCollection(limit: $limit, order: publishedOn_DESC) {
      total
      items {
        slug
        title
        author {
          name
        }
        excerpt
        publishedOn
      }
    }
  }
`;

export default POSTSRSS;
