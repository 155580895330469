const ASIDE_POST_COLLECTION = gql`
  query ASIDE_POST_COLLECTION($limit: Int) {
    pageBlogPostCollection(limit: $limit, order: publishedOn_DESC) {
      items {
        title
        slug
        publishedOn
        featuredMedia {
          description
          url
        }
      }
    }
  }
`;

export default ASIDE_POST_COLLECTION;
