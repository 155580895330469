import gql from "graphql-tag";

const CATEGORIES = gql`
  query CATEGORIES {
    categoryCollection {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

export default CATEGORIES;
