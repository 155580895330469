import ComponentCardRt from "./ComponentCardRt";
import ComponentCardRtDownload from "./ComponentCardDownload";

const ComponentGrid = gql`
  fragment ComponentGrid on ComponentGrid {
    layout
    type
    itemsCollection {
      total
      items {
        ...ComponentCardRt
        ...ComponentCardRtDownload
      }
    }
  }

  ${ComponentCardRt}
  ${ComponentCardRtDownload}
`;

export default ComponentGrid;
