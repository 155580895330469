import {
  ImageFocusAreas,
  ImageFormats,
  ImageScaleRatios
} from "~/types/contentful/assets/images.enums";

//mobile first
enum FrontiersImageMedia {
  Mobile = "(max-width: 767px)",
  TabletSm = "(min-width: 768px and max-width: 1023px)",
  Tablet = "(min-width: 1024px and max-width: 1279px)",
  LaptopSm = "(min-width: 1280px and max-width: 1439px)",
  Laptop = "(min-width: 1440px and max-width: 1919px)",
  Desktop = "(min-width: 1920px)"
}

const FrontiersRichTextImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 380,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 642,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 824,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 644,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 672,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 912,
      format: ImageFormats.WEBP,
      quality: 80
    }
  }
];

const AsidePostImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 106,
      height: 106,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 78,
      height: 78,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 110,
      height: 110,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 48,
      height: 48,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 56,
      height: 56,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 80,
      height: 80,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  }
];

const AuthorAsideImageQueries = [
  {
    width: 48,
    height: 48,
    focusImage: ImageFocusAreas.center,
    scaleImage: ImageScaleRatios.fill,
    format: ImageFormats.WEBP,
    quality: 80
  }
];

const AuthorPageImageQueries = [
  {
    width: 128,
    height: 128,
    focusImage: ImageFocusAreas.center,
    scaleImage: ImageScaleRatios.fill,
    format: ImageFormats.WEBP,
    quality: 80
  }
];

const BriefPostImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 396,
      height: 170,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 76,
      height: 76,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 108,
      height: 108,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 70,
      height: 70,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 84,
      height: 84,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 124,
      height: 124,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  }
];

const MainHeaderImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 412,
      height: 282,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 768,
      height: 240,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 1024,
      height: 240,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 1280,
      height: 340,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 1440,
      height: 392,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 1920,
      height: 392,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      format: ImageFormats.WEBP,
      quality: 80
    }
  }
];

const CardNewsMainImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 380,
      height: 213,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 644,
      height: 362,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 824,
      height: 463,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 644,
      height: 362,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 672,
      height: 378,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 912,
      height: 513,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  }
];

const CardNewsSecondaryImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 380,
      height: 213,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 313,
      height: 176,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 404,
      height: 227,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 306,
      height: 172,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 320,
      height: 180,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 440,
      height: 247,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  }
];

const CardNewsLatestImageQueries = [
  {
    media: FrontiersImageMedia.Mobile,
    query: {
      width: 380,
      height: 213,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.TabletSm,
    query: {
      width: 420,
      height: 219,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Tablet,
    query: {
      width: 362,
      height: 204,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.LaptopSm,
    query: {
      width: 306,
      height: 172,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Laptop,
    query: {
      width: 320,
      height: 180,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  },
  {
    media: FrontiersImageMedia.Desktop,
    query: {
      width: 282,
      height: 158,
      format: ImageFormats.WEBP,
      focusImage: ImageFocusAreas.center,
      scaleImage: ImageScaleRatios.fill,
      quality: 80
    }
  }
];

export {
  FrontiersRichTextImageQueries,
  AsidePostImageQueries,
  AuthorAsideImageQueries,
  AuthorPageImageQueries,
  BriefPostImageQueries,
  MainHeaderImageQueries,
  CardNewsMainImageQueries,
  CardNewsSecondaryImageQueries,
  CardNewsLatestImageQueries
};
