import gql from "graphql-tag";

const TAGS = gql`
  query TAGS {
    tagCollection {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

export default TAGS;
