const ComponentCardRt = gql`
  fragment ComponentCardRt on ComponentCardRt {
    title
    text
    authors
    image {
      title
      description
      url
    }
    totalViews
    totalArticles
    cardLinkUrl
  }
`;

export default ComponentCardRt;
