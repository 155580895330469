import gql from "graphql-tag";

const POSTS = gql`
  query POSTS($limit: Int, $skip: Int) {
    pageBlogPostCollection(limit: $limit, skip: $skip) {
      total
      items {
        slug
        publishedOn
        sys {
          publishedAt
        }
      }
    }
  }
`;

export default POSTS;
