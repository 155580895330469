import {
  getCategoriesByGroupLevel1,
  getCategoriesByGroupLevel2,
  getCategoriesEligibleForPosts
} from "~/helpers/getCategories";
import { mapToCategory } from "~/mappers/components";
import { useContentfulQuery } from "~/services";
import { useIbarFooterService } from "~/services/useIbarFooterService";
import type { IAppMainStoreState } from "~/types/app/IAppStoresState";
import type { IFooter, IIbar } from "~/types/journal-pages-2022";
import type { ICategory, ICategoryGroup } from "~/types/page-service";
import type { ILandingPageResponse } from "~/types/contentful/responses/ILandingPageResponse";

export const useMain = defineStore("main", {
  state: (): IAppMainStoreState => {
    return {
      ibar: {} as IIbar,
      footer: {} as IFooter,
      categoryList: [] as Array<ICategory>,
      postTotalResult: 0,
      postListPageSize: 15,
      landingPage: {} as ILandingPageResponse
    };
  },
  getters: {
    categoryGroupsLevel1(state): Array<ICategoryGroup> {
      return getCategoriesByGroupLevel1(state.categoryList);
    },
    categoryGroupsLevel2(): Array<ICategory> {
      return getCategoriesByGroupLevel2(
        this.categoryList,
        this.categoryGroupsLevel1
      );
    },
    categoryListEligibleForPosts(state): Array<ICategory> {
      return getCategoriesEligibleForPosts(state.categoryList);
    }
  },
  actions: {
    async fetchIbar() {
      const ibarFooterService = useIbarFooterService();
      const ibar = await ibarFooterService.getIbarComponent();
      this.ibar = ibar;
    },

    async fetchFooter() {
      const ibarFooterService = useIbarFooterService();
      const footer = await ibarFooterService.getFooterComponent();
      this.footer = footer;
    },

    async fetchCategories() {
      const contentfulService = useContentfulQuery();
      const categoryList = await contentfulService.getCategoryList();
      this.categoryList = categoryList
        .map((c) => mapToCategory(c))
        .sort((a: any, b: any) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
        );
    },
    async fetchLandingPage() {
      const contenfulService = useContentfulQuery();
      this.landingPage = await contenfulService.getLandingPage();
    },

    updatePostTotalResult(totalResult: number) {
      this.postTotalResult = totalResult;
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMain, import.meta.hot));
}
